import React from "react";

const ReadyToStart = () => {
  return (
    <section className="rts_section" id="contact-us">
      <div className="container my-5">
        <div className="p-5 text-center bg-body-tertiary rounded-3">
          <span className="rts_icon_span">
            <i className="fas fa-check"></i>
          </span>
          <h1 className="text-body-emphasis mt-4 fw-bolder">
            Ready To Start A <span className="text-primary">Project</span> With
            Us?
          </h1>
          <p className="col-lg-8 mx-auto fs-5 text-muted">
            Ready to Unlock the Power of Market <br /> Research and Drive
            Business Growth?
          </p>
          <div className="d-inline-flex flex-column gap-2 mb-5">
            <button
              className="d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill"
              type="button"
            >
              <i className="fa fa-arrow-down me-3"></i> Get to know us better{" "}
              <i className="fa fa-arrow-down ms-3"></i>
            </button>
            <button
              className="btn btn-outline-secondary btn-lg px-4 rounded-pill"
              type="button"
            >
              Call: 9355755745
            </button>
            <button
              className="btn btn-outline-secondary btn-lg px-4 rounded-pill"
              type="button"
            >
              Email: info@nexageo.com
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReadyToStart;
