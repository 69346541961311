import React from "react";

const AboutUs = () => {
  return (
    <section className="about-us" id="about-us">
      <div className="container">
        <div className="px-4 py-5 my-5 text-center">
          <span className="about_us_icon_span">
            <i className="fas fa-building"></i>
          </span>
          <h1 className="display-5 fw-bold text-white">About Us</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
              Nexageo is a leading market research agency that specializes in
              assisting organizations worldwide in optimizing their end-to-end
              research processes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
