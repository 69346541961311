import React from "react";

const Process = () => {
  return (
    <section className="py-sm-5 py-4" id="process">
      <div className="container py-sm-5 py-4">
        <div className="d-flex flex-column align-items-center mb-sm-5 mb-4">
          <h2 className="fw-bold text-body-emphasis mb-0">OUR PROCESS</h2>
          <p className="text-body-secondary">
            Focusing on the 3 key elements of any successful market research
            strategy.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 col-sm-12 col-12 text-center overflow-hidden bg-body-tertiary">
            <div className="my-3 py-3">
              <h2 className="fs-5 text-dark">1. Data Collection</h2>
              <p className="fs-6">
                Gathering relevant and accurate data from various sources, such
                as surveys and interviews.
              </p>
            </div>
            <div
              className="bg-body-tertiary shadow-sm mx-auto"
              style={{
                width: "80%",

                borderRadius: "21px 21px 0 0",
              }}
            >
              <img src="./assets/img/process1.jpg" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-primary text-white col-md-6 col-lg-4 col-sm-12 col-12 text-center overflow-hidden shadow-lg">
            <div className="my-3 p-3">
              <h2 className="fs-5 text-white">2. Data Analysis</h2>
              <p className="fs-6">
                Organizing and interpreting data to uncover patterns, trends,
                and relationships.
              </p>
            </div>
            <div
              className="bg-dark shadow-sm mx-auto"
              style={{
                width: "80%",

                borderRadius: "21px 21px 0 0",
              }}
            >
              <img src="./assets/img/process2.avif" alt="" className="w-100" />
            </div>
          </div>
          <div className="bg-body-tertiary col-md-6 col-lg-4 col-sm-12 col-12 text-center overflow-hidden">
            <div className="my-3 p-3">
              <h2 className="fs-5 text-dark">3. Insights & Reporting</h2>
              <p className="fs-6">
                Transforming data into clear, concise reports that provide
                actionable recommendations
              </p>
            </div>
            <div
              className="bg-dark shadow-sm mx-auto"
              style={{
                width: "80%",

                borderRadius: "21px 21px 0 0",
              }}
            >
              <img src="./assets/img/process3.jpg" alt="" className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process;
