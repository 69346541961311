import React from "react";

const Hero = () => {
  return (
    <section id="hero" className="hero d-flex align-items-center shadow-lg">
      <div className="container">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-12 col-lg-6">
            <img
              src="./assets/img/banner.png"
              className="d-block mx-lg-auto img-fluid hero_banner_img"
              alt="Image"
              loading="lazy"
            />
          </div>
          <div className="col-12 col-lg-6">
            <h1 className="display-5 fw-bold  text-white lh-1 mb-3">
              We are the provider whom you need
            </h1>
            <p className="lead text-white">
              We provide Quantitative Research, Business Research , Face 2 Face
              Research, Primary Research, Secondary Research, Qualitative
              Research etc. services.
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <button
                type="button"
                className="btn btn-primary btn-lg px-4 me-md-2"
              >
                <i className="fa fa-phone"></i> Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
