import React from "react";

const Services = () => {
  return (
    <section className="bg-light" id="services">
      <div className="container px-4 py-5">
        <div className="row py-5">
          <div className="col-12 d-flex flex-column align-items-center">
            <h2 className="fw-bold text-body-emphasis mb-0">What We Do</h2>
            <p className="text-body-secondary">
              We Believe in Building Strong Brands and Integrated Strategies.
            </p>
          </div>

          <div className="col-12 mt-3">
            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 g-4">
              <div className="col d-flex flex-column gap-2 shadow bg-white p-4">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                  <i className="fa fa-paper-plane"></i>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">
                  Quantitative Research
                </h4>
                <p className="text-body-secondary text-justify">
                  Quantitative research involves the systematic collection and
                  analysis of numerical data using statistical or computational
                  techniques. This approach allows organizations to make
                  evidence-based conclusions.
                </p>
              </div>

              <div className="col d-flex flex-column gap-2 shadow bg-white p-4">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                  <i className="fa fa-paper-plane"></i>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">
                  Business Research
                </h4>
                <p className="text-body-secondary text-justify">
                  Burgeon business research team specializes in providing
                  innovative, cost effective and high quality tailored market
                  intelligence to help our customers to better understand their
                  markets so they lead.
                </p>
              </div>

              <div className="col d-flex flex-column gap-2 shadow bg-white p-4">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                  <i className="fa fa-paper-plane"></i>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">
                  Face 2 Face Research
                </h4>
                <p className="text-body-secondary text-justify">
                  In case of Face to face research the interviewer physically
                  travels to the respondent’s location to conduct a personal
                  interview. In this survey a standardized script is followed
                  without deviation, just like a telephone survey.
                </p>
              </div>

              <div className="col d-flex flex-column gap-2 shadow bg-white p-4">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                  <i className="fa fa-paper-plane"></i>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">
                  Primary Research
                </h4>
                <p className="text-body-secondary text-justify">
                  At Primary Research, we put the emphasis on finding solutions
                  to marketing problems without being tied to a All about so
                  particular technique or methodology.
                </p>
              </div>

              <div className="col d-flex flex-column gap-2 shadow bg-white p-4">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                  <i className="fa fa-paper-plane"></i>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">
                  Secondary Research
                </h4>
                <p className="text-body-secondary text-justify">
                  By far the most widely used method for collecting data is
                  through secondary data collection, commonly called secondary
                  research.
                </p>
              </div>

              <div className="col d-flex flex-column gap-2 shadow bg-white p-4">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                  <i className="fa fa-paper-plane"></i>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">
                  Qualitative Research
                </h4>
                <p className="text-body-secondary text-justify">
                  Qualitative market research is an effective research tool that
                  market research agencies employ in market research and the
                  social studies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
