import React from "react";

const Footer = () => {
  return (
    <footer className="bg-dark-primary">
      <div className="container">
        <footer className="py-3">
          <ul className="nav justify-content-center pb-3 mb-1">
            <li className="nav-item">
              <a className="nav-link text-white" href="#">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="#services">
                Services
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="#about-us">
                About Us
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link text-white" href="#process">
                Our Process
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="#contact-us">
                Contact Us
              </a>
            </li>
          </ul>
          <p className="text-center text-white">
            © {new Date().getFullYear()} Nexageo, Images used from{" "}
            <a
              href="https://www.freepik.com/"
              className="text-warning"
              target="_BLANK"
            >
              FreePik
            </a>
          </p>
        </footer>
      </div>
    </footer>
  );
};

export default Footer;
