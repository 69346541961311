import React from "react";
import ReadyToStart from "../../components/website/ReadyToStart";
import Hero from "../../components/website/Hero";
import Services from "../../components/website/Services";
import AboutUs from "../../components/website/AboutUs";
import Process from "../../components/website/Process";

const Homepage = () => {
  return (
    <div>
      <Hero />
      <Services />
      <AboutUs />
      <Process />
      <ReadyToStart />
    </div>
  );
};

export default Homepage;
